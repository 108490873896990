import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Top from "../../../images/reference/aktin/top.jpg"
import Logo from "../../../images/reference/aktin/logo.png"
import Header from "../../../images/reference/aktin/header-devel.png"
import Sideimg from "../../../images/reference/aktin/screen-1.png"
import Michal from "../../../images/reference/aktin/reaction-devel.png"

class Aktin extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na nový e-shop Aktin | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//aktin.cz"
          heading="Reference na nový e-shop Aktin"
          odstavec="únor 2014 - červenec 2017"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Aktin vznikl v roce 2010 jako internetový fitness magazín. S rostoucím zájmem čtenářů se zvyšovaly i náklady na jeho provoz. Proto se dvojice jeho tvůrců rozhodla založit o rok později společnost Selltime s.r.o., pod jejíž hlavičkou funguje tematicky zaměřený e-shop. Aktin v současnosti provozuje dvě vlastní fitness prodejny a podporuje desítky atletů a osobností prosazující aktivní způsob života."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p>
                Realizovat e-shop pro unikátní webový projekt, zařídit
                technickou realizaci a konzultace návrhů.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>
                  vývoj e-shopového řešení na míru pro obchodní část portálu
                </li>
                <li>technické konzultace, vývoj a testování nových funkcí</li>
                <li>konzultace grafického řešení</li>
                <li>
                  responzivní front-end design pro e-shopovou i administrační
                  část
                </li>
                <li>kompletní zajištění hostingu a IT prostředků pro e-shop</li>
              </ul>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Sideimg}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Historie spolupráce</TitleH2>
              <p>
                S projektem Aktin jsme se poprvé setkali již před několika lety
                jako poskytovatelé webhostingu. Po krátké přestávce ve
                spolupráci se k nám klient opět vrátil s ideou začít pracovat na
                vývoji nového e-shopu pro jejich fitness portál.
              </p>
              <p>
                Začátkem roku 2014 jsme dostali první návrhy grafiky a nápadů,
                jak by měl budoucí e-shop vypadat. Na tomto projektu jsme
                zároveň vyvíjeli naše nové e-shopové jádro a Aktin byl tak jeho
                prvním uživatelem.
              </p>
              <p>
                Pokračovali jsme vývojem portálové části projektu a následně se
                zaměřili i na spolupráce při propagaci projektu a obecně online
                marketing.
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <PaddingWrapper>
            <HalfWidthBlock>
              <TitleH2>Zhodnocení spolupráce</TitleH2>
              <p>
                Naše spolupráce na vývoji a marketingu pomohla projektu Aktin
                vyrůst do takové míry, že si společnost založila vlastní
                vývojový tým a naši další spolupráce již nebylo třeba. Jistě
                tomu přispěl náš e-shop postavený na Nette frameworku a licenční
                podmínky, které nezávislý rozvoj našich klientů umožňují.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>

          <Medailon
            wide
            image={Michal}
            alt=""
            text="Spolupráce proběhla dle našich vysokých očekávání. Zejména výjimečně
            talentovaní lidé, schopnost plnit nestandardní přání a hbitá komunikace stojí
            za úspěchem projektu."
            name="Michal Hubík"
            position="manažer vývoje"
          />
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default Aktin
